import React, { Component } from 'react';
import { Label, Button, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LocalForm, Control, Errors, actions } from 'react-redux-form';

import FirebaseContext from './Firebase/FirebaseContext';
import { uploadString } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";

const required = (val) => val && val.length;
const minLength = (len) => (val) => !(val) || (val.length >= len);
const validEmail = (val) => !(val) || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

class ContactUs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            isNewsletterModalOpen: false
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.toggleNewsletterModal = this.toggleNewsletterModal.bind(this);

        this.handleSubmitFeedback = this.handleSubmitFeedback.bind(this);
        this.handleSubmitNewsletter = this.handleSubmitNewsletter.bind(this);
    }

    toggleModal() {
        this.setState({ isModalOpen: !this.state.isModalOpen});
    }

    toggleNewsletterModal() {
        this.setState({ isNewsletterModalOpen: !this.state.isNewsletterModalOpen});
    }

    handleSubmitFeedback(feedback, firebase) {
        var request = JSON.stringify(feedback).toString();
        var fileName = Math.round(Math.random() * 10000000000000);

        const requestsRef = firebase.getAppStorage('feedback/' + fileName.toString() + '.txt');
        uploadString(requestsRef, request)
            .then(snapshot => {
                this.toggleModal();
                this.feedbackFormDispatch(actions.reset('feedback'));

            }, err => {
                this.handleSubmitFeedback(feedback);
            })
            .catch(err => {
                console.log(err.message)
            });
    }

    handleSubmitNewsletter(values, firebase) {
        var emailString = values.email;

        const db = firebase.getAppFirestore();

        setDoc(doc(db, 'emails', emailString), { 'v': true })
            .then(snapshot => {
                this.toggleNewsletterModal();
                this.newsletterFormDispatch(actions.reset('newsletter'));

            }, err => {
                console.log(err.message);
            })
            .catch(err => {
                console.log(err.message)
            });
    }

    render() {

        const FeedbackForm = ({ firebase }) => {
            return (
            <LocalForm model="feedback" onSubmit={(values) => this.handleSubmitFeedback(values, firebase)}
                getDispatch={(dispatch) => this.feedbackFormDispatch = dispatch} >
                <div className="row form-group" style={{ marginBottom: '18px' }}>
                    <Label for="firstname" md={2}>Name</Label>
                    <Col md={10}>
                        <Control.text model=".name" id="name" name="name" placeholder="Name"
                            className="form-control" validators={{ required, minLength: minLength(3) }} />
                        <Errors
                            className="text-danger" model=".name" show="touched"
                            messages={{
                              required: 'Required',
                              minLength: 'Must be greater than 2 characters'
                            }} />
                    </Col>
                </div>
                <div className="row form-group" style={{ marginBottom: '18px' }}>
                    <Label for="email" md={2}>Email</Label>
                    <Col md={10}>
                        <Control.text model=".email" id="email" name="email" placeholder="Email"
                          className="form-control" validators={{required, validEmail}} />
                          <Errors
                              className="text-danger" model=".email" show="touched"
                              messages={{
                                required: 'Required',
                                validEmail: 'Invalid email address'
                              }} />
                    </Col>
                </div>
                <div className="row form-group" style={{ marginBottom: '18px' }}>
                    <Col md={{size: 6, offset: 2}}>
                        <div className="form-check">
                            <Label check>
                                <Control.checkbox model=".agree" name="agree" className="form-check-input" /> {' '} <strong>May we contact you?</strong>
                            </Label>
                        </div>
                    </Col>
                </div>

                <div className="row form-group" style={{ marginBottom: '18px' }}>
                    <Label className="form-label" for="message" md={2}>Your Feedback</Label>
                    <Col md={{size: 10}}>
                        <Control.textarea model=".message" id="message" name="message" rows="12"
                            className="form-control" validators={{required}} />
                        <Errors
                            className="text-danger" model=".message" show="touched"
                            messages={{
                              required: 'Required'
                            }} />
                    </Col>
                </div>
                <div className="row form-group">
                    <Col md={{size: 10, offset: 2}}>
                        <Button color="primary" type="submit" >Send Feedback</Button>
                    </Col>
                </div>
            </LocalForm>
        )}

        const NewsletterForm = ({ firebase }) => {
            return (
                <LocalForm model="newsletter" onSubmit={(values) => this.handleSubmitNewsletter(values, firebase)}
                    getDispatch={(dispatch) => this.newsletterFormDispatch = dispatch} >
                    <div className="row form-row" style={{ marginBottom: '30px', marginTop: '30px' }}>
                        <div className="form-group col-12 col-md-6">
                            <div className="col-12">
                                <Control.text model=".email" id="email" name="email"
                                    placeholder="Enter your email address..."
                                    className="form-control" validators={{required, validEmail}} />
                                <Errors
                                    className="text-danger" model=".email" show="touched"
                                    messages={{
                                        required: "Required field.",
                                        validEmail: "Invalid email address."
                                    }} />
                            </div>
                        </div>
                        <div className="form-group col-12 col-md-6">
                            <Button color="primary" type="submit">Subscribe</Button>
                        </div>
                    </div>
                </LocalForm>
            )
        }

        // <i className="fa fa-twitter m-2"></i> <a href="https://www.twitter.com/bondedworld" target="_blank" rel="noopener noreferrer">twitter</a>
        // <br />
        return (
            <div>
                <div className="container">
                    <div className="city-header">
                        <h1>Contact Us</h1>
                    </div>
                    <div style={{ textAlign: 'left' }}>
                        <div className="row">
                            <div className="col-12 col-sm-4 offset-sm-2">
                                <h5>Follow us on: </h5>
                                <address>
                                    <i className="fa fa-facebook-square m-2"></i> <a href="https://www.facebook.com/bondedworld" target="_blank" rel="noopener noreferrer">facebook</a>
                                    <br />
                                    <i className="fa fa-instagram m-2"></i> <a href="https://instagram.com/bondedworld" target="_blank" rel="noopener noreferrer">instagram</a>
                                    <br />
                                    <i className="fa fa-youtube m-2"></i> <a href="https://www.youtube.com/channel/UCfu-eFk-tUuYZOwGhpf7qGQ/" target="_blank" rel="noopener noreferrer">youtube</a>
                                    <br />
                                    <i className="fa fa-envelope m-2"></i> <a href="mailto:support@bondedworld.com">support@bondedworld.com</a>
                                    <br/>
                                </address>
                            </div>
                            <div className="col-12 col-sm-4">
                                <p>
                                Need help on planning your next travel adventure?
                                <br/><br/>
                                Visit us at <a href="/plan-my-trip">Plan My Trip</a> and we will help you get the best out of your next journey.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-10 m-auto">
                            <div className="city-topic">
                                <h2 style={{ paddingTop: '20px', marginBottom: '15px' }}>Subscribe To Our Newsletter</h2>
                                <p>Subscribe to our Newsletter and get notifications by email when we release new travel guides and tips.</p>
                                <FirebaseContext.Consumer>
                                    {(firebase) => {
                                        return( <NewsletterForm firebase={ firebase } /> )
                                    }}
                                </FirebaseContext.Consumer>
                            </div>
                        </div>

                        <div className="col-12 col-lg-10 m-auto">
                            <div className="city-topic">
                                <h2 style={{ paddingTop: '20px', marginBottom: '15px' }}>Send Us Your Feedback</h2>
                                <p style={{ marginBottom: '30px' }}>Help us improve by giving some feedback on the website.</p>
                                <div className="col-12 col-md-9 col-lg-8">
                                    <FirebaseContext.Consumer>
                                        {(firebase) => {
                                            return( <FeedbackForm firebase={ firebase } /> )
                                        }}
                                    </FirebaseContext.Consumer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal isOpen={ this.state.isModalOpen } toggle={ this.toggleModal } centered>
                    <ModalHeader toggle={ this.toggleModal }>Feedback Sent Successfully!</ModalHeader>
                    <ModalBody toggle={ this.toggleModal }>
                        Thanks for submitting your feedback.<br/> We are always looking for ways to improve the website and will pay close attention to what you said.
                    </ModalBody>
                    <ModalFooter>
                          <Button color="secondary" onClick={ this.toggleModal }>Close</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={ this.state.isNewsletterModalOpen } toggle={ this.toggleNewsletterModal } centered>
                    <ModalHeader toggle={ this.toggleNewsletterModal }>Subscribed To Our Newsletter!</ModalHeader>
                    <ModalBody toggle={ this.toggleNewsletterModal }>
                        Thanks for subscribing to our newsletter.<br/> We will be sending you emails everytime a new travel guide or travel deal appears.
                    </ModalBody>
                    <ModalFooter>
                          <Button color="secondary" onClick={ this.toggleNewsletterModal }>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default ContactUs;
